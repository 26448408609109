import React, { memo, useEffect, useState } from "react";
import {
  ClickAwayListener,
  FormControl,
  Icon,
  Input,
  InputAdornment,
  InputLabel,
  withStyles,
} from "@material-ui/core";
import {
  openSearchOption,
  SearchResultsPopper,
} from "./Productions/HeadlineSearch";
import { getCulturalExpressions } from "../data/CulturalExpressionRepository";
import { getTranslationText } from "../utils/LocalizationUltils";

export default memo((props) => {
  const [culturalExpressions, setCulturalExpressions] = useState([]);

  useEffect(() => {
    getCulturalExpressions().then((r) => setCulturalExpressions(r));
  }, []);

  return (
    <section className={"kt-explore-productions"}>
      <div className='kt-indent-74'>
        <h2>Søk blant våre produksjoner</h2>
        <div>
          <ExploreProductionsSearch url={props.url} />

          <div className={"kt-explore-productions-cultural-expressions"}>
            {culturalExpressions
              .filter(
                ({ parentCulturalExpressionId }) => !parentCulturalExpressionId
              )
              .map((c) => (
                <CulturalExpression key={c.id} url={props.url} {...c} />
              ))}
          </div>
        </div>
      </div>
    </section>
  );
});

const CulturalExpression = memo((props) => {
  return (
    <a
      href={
        props.url +
        "#/" +
        btoa(JSON.stringify({ culturalExpressionIds: [props.id] }))
      }>
      <img
        src={
          window.APP_SERVICE +
          "/img/cultural-expressions/" +
          props.icon +
          ".svg"
        }
        alt={props.name}
      />
      <p>{props.name}</p>
    </a>
  );
});

const StyledInputLabel = withStyles({
  root: {
    color: "#000 !important",
  },
})(InputLabel);

const StyledInput = withStyles({
  root: {
    color: "inherit !important",
  },
  underline: {
    "&:after": {
      borderBottomColor: "#000 !important",
    },
    "&:before": {
      borderBottomColor: "#000 !important",
    },
  },
})(Input);

class ExploreProductionsSearch extends React.PureComponent {
  state = {
    query: "",
    open: false,
  };

  handleClose = () => this.setState({ open: false });
  handleChange = (e) => {
    const query = e.target.value;
    this.setState({ query: query });
    if (!this.state.open && query.length > 1) {
      this.setState({ open: true });
    }

    if (this.state.open && query.length <= 1) {
      this.setState({ open: false });
    }
  };

  render() {
    const { query, open } = this.state;
    const { url } = this.props;

    return (
      <ClickAwayListener onClickAway={this.handleClose}>
        <div className={"kt-explore-productions-search"}>
          <FormControl fullWidth>
            <StyledInputLabel className={"kt-explore-productions-search-label"}>
              {window.WP_IS_MAIN_SITE
                ? "Søk etter fylke, kommune, skole eller produksjon."
                : getTranslationText(window.SITE_LANGUAGE, "search.production.title")
              }
            </StyledInputLabel>
            <StyledInput
              fullWidth
              value={query}
              onChange={this.handleChange}
              endAdornment={
                <InputAdornment position={"end"}>
                  <Icon>search</Icon>
                </InputAdornment>
              }
            />
          </FormControl>

          <SearchResultsPopper
            open={open}
            query={query}
            url={url}
            onClose={this.handleClose}
            onSelect={openSearchOption(url)}
          />
        </div>
      </ClickAwayListener>
    );
  }
}

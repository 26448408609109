var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { memo, useState } from "react";
import { CircularProgress, ClickAwayListener, Fade, FilledInput, FormControl, Icon, Input, InputAdornment, InputLabel, List, ListItem, ListItemText, Paper, withStyles, ListItemIcon, } from "@material-ui/core";
import { useAsync, useDebounce } from "react-use";
import { FONT_FAMILY } from "../../utils/Constants";
import { getTranslation } from "../../utils/LocalizationUltils";
import { encodeFilterOptions } from "../../utils/StringUtils";
var StyledInputLabel = withStyles({
    root: {
        color: "inherit !important",
        fontSize: "1rem",
        fontFamily: FONT_FAMILY,
        paddingRight: "60px",
        "&.Mui-focused": {
            fontSize: "0.9rem",
            transform: "translateY(-5px)",
        },
    },
})(InputLabel);
var StyledInput = withStyles({
    root: {
        color: "inherit !important",
    },
    underline: {
        "&:after": {
            borderBottomColor: "#inherit !important",
        },
        "&:before": {
            borderBottomColor: "#inherit !important",
        },
    },
})(Input);
var StyledFilledInput = withStyles({
    root: {
        color: "inherit !important",
        background: "transparent !important",
        borderBottomColor: "inherit !important",
    },
    underline: {
        "&:before": {
            borderBottomColor: "inherit !important",
        },
        "&.Mui-focused": {
            "&:after": {
                borderBottomColor: "inherit !important",
            },
        },
    },
})(FilledInput);
export var openSearchOption = function (url) { return function (option) {
    var _a;
    if (option.type === SearchOptionType.production) {
        window.location.href = url + "?produksjon=" + option.id;
        return;
    }
    var requestParams = {
        view: SearchOptionType.school === option.type ? ViewType.calendar : ViewType.list
    };
    switch (option.type) {
        case SearchOptionType.groupName:
            requestParams['groupNames'] = [option.name];
            break;
        case SearchOptionType.performer:
            requestParams['performers'] = [option];
        default:
            requestParams = __assign(__assign({}, requestParams), (_a = {}, _a[option.type + "Ids"] = [option.id], _a));
    }
    window.location.href = url + "#/" + encodeFilterOptions(requestParams);
    return;
}; };
export default memo(function (_a) {
    var _b;
    var variant = _a.variant, props = __rest(_a, ["variant"]);
    var _c = useState(false), open = _c[0], setOpen = _c[1];
    var _d = useState(""), query = _d[0], setQuery = _d[1];
    // @ts-ignore
    var languages = window.LANGUAGES;
    // @ts-ignore
    var siteLanguage = (_b = window.SITE_LANGUAGE) !== null && _b !== void 0 ? _b : 'nb';
    var handleClose = function () { return setOpen(false); };
    var handleChange = function (e) {
        var query = e.target.value;
        setQuery(query);
        if (!open && query.length > 1) {
            setOpen(true);
        }
        if (open && query.length <= 1) {
            setOpen(false);
        }
    };
    var InputComponent = variant === "filled" ? StyledFilledInput : StyledInput;
    return (React.createElement(ClickAwayListener, { onClickAway: function () { return setOpen(false); } },
        React.createElement("div", { style: { width: "100%", position: "relative" } },
            React.createElement(FormControl, { fullWidth: true, variant: variant },
                React.createElement(StyledInputLabel, null, getTranslation(languages, siteLanguage, 'wordpress_production_view_fields', 'search_by_school_production_or_performer')),
                React.createElement(InputComponent, { fullWidth: true, value: query, onChange: handleChange, endAdornment: React.createElement(InputAdornment, { position: "end" },
                        React.createElement(Icon, null, "search")) })),
            React.createElement(SearchResultsPopper, __assign({ clearOnSelect: true, open: open, query: query, onClose: handleClose }, props)))));
});
var ViewType;
(function (ViewType) {
    ViewType["calendar"] = "calendar";
    ViewType["list"] = "list";
})(ViewType || (ViewType = {}));
var SearchResultsPopper = memo(function (_a) {
    var clearOnSelect = _a.clearOnSelect, open = _a.open, query = _a.query, url = _a.url, onClose = _a.onClose, onSelect = _a.onSelect, filters = _a.filters, onFiltersChange = _a.onFiltersChange;
    /// Get search results
    var _b = useState(query), debouncedQuery = _b[0], setDebouncedQuery = _b[1];
    useDebounce(function () { return setDebouncedQuery(query); }, 250, [query]);
    var state = useAsync(function () { return open ? getSearchResult(debouncedQuery) : new Promise(function () { return null; }); }, [open, debouncedQuery]);
    var handleSelect = function (option) { return function () {
        if (onSelect) {
            onSelect(option);
            return;
        }
        if (onFiltersChange) {
            var newFilters = __assign({}, filters);
            if (clearOnSelect) {
                newFilters.schoolIds = [];
                newFilters.branchOfficeIds = [];
                newFilters.performerIds = [];
                newFilters.performers = [];
                newFilters.groupNames = [];
            }
            switch (option.type) {
                case SearchOptionType.school:
                    newFilters.schoolIds = __spreadArrays((newFilters.schoolIds || []), [option.id]);
                    newFilters.view = ViewType.calendar;
                    break;
                case SearchOptionType.branchOffice:
                    newFilters.branchOfficeIds = __spreadArrays((newFilters.branchOfficeIds || []), [option.id,]);
                    break;
                case SearchOptionType.performer:
                    newFilters.performerIds = __spreadArrays((newFilters.performerIds || []), [option.id]);
                    newFilters.performers = __spreadArrays((newFilters.performers || []), [{ id: option.id, name: option.name }]);
                    break;
                case SearchOptionType.groupName:
                    newFilters.groupNames = __spreadArrays((newFilters.groupNames || []), [option.name]);
                    break;
                case SearchOptionType.production:
                    window.location.href = (url || "") + "?produksjon=" + option.id;
                    return;
            }
            onFiltersChange(newFilters);
        }
        onClose();
    }; };
    return (React.createElement("div", { style: {
            position: "absolute",
            width: "100%",
            zIndex: 1000,
            pointerEvents: open ? undefined : "none",
        } },
        React.createElement(Fade, { in: open, timeout: 350 },
            React.createElement(Paper, { square: true, elevation: 4, style: {
                    background: "#FFF",
                    color: "rgba(0,0,0,0.8)",
                    maxHeight: "400px",
                    overflowY: "auto",
                } }, state.value ? (React.createElement(List, null,
                state.value.map(function (v) { return (React.createElement(ListItem, { key: v.type + v.id, dense: true, button: true, onClick: handleSelect(v) },
                    React.createElement(ListItemIcon, { style: { minWidth: "40px" } },
                        React.createElement("i", { className: "material-icons", style: { color: v.icon.color } }, v.icon.type)),
                    React.createElement(ListItemText, { primary: v.name }))); }),
                state.value.length === 0 ? (React.createElement(ListItem, null,
                    React.createElement(ListItemText, { primary: "Ingen resultater funnet" }))) : null)) : (React.createElement("div", { style: {
                    padding: "20px",
                    display: "flex",
                    justifyContent: "center",
                } },
                React.createElement(CircularProgress, { size: 25 })))))));
});
export { SearchResultsPopper };
var SearchOptionType;
(function (SearchOptionType) {
    SearchOptionType["production"] = "production";
    SearchOptionType["branchOffice"] = "branchOffice";
    SearchOptionType["school"] = "school";
    SearchOptionType["groupName"] = "groupName";
    SearchOptionType["performer"] = "performer";
})(SearchOptionType || (SearchOptionType = {}));
var IconType;
(function (IconType) {
    IconType["local_play"] = "local_play";
    IconType["location_on"] = "localtion_on";
    IconType["school"] = "school";
    IconType["group_name"] = "group_name";
    IconType["performer"] = "performer";
})(IconType || (IconType = {}));
function getSearchResult(query) {
    return __awaiter(this, void 0, void 0, function () {
        var appService, wordpressHomeUrl, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (query.length <= 1) {
                        return [2 /*return*/, new Promise(function () { return null; })];
                    }
                    appService = window.APP_SERVICE;
                    wordpressHomeUrl = window.WORDPRESS_HOME_URL;
                    return [4 /*yield*/, fetch(appService +
                            "/api/wordpress/productions/search?q=" +
                            encodeURI(query) +
                            "&limit=20&wordpressHomeUrl=" +
                            wordpressHomeUrl)];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2: return [2 /*return*/, (_a.sent())];
            }
        });
    });
}

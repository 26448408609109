import LoadingIndicator from "./LoadingIndicator";
import React from "react";

const page = <div style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '50px',
}}>
    <LoadingIndicator/>
</div>;

export default () => page;
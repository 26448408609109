import React from "react";
import * as ReactDOM from "react-dom";

export default class AnchorDiv {
    static attachDivToBody(){
        const sNew = document.createElement("div");
        document.getElementsByTagName('body')[0].appendChild(sNew);
        return sNew;
    }

    static renderModal(Component: React.Component){
        const sNew = document.createElement("div");
        document.getElementsByTagName('body')[0].appendChild(sNew);

        ReactDOM.render(
            <Component onClose={() => {
                ReactDOM.unmountComponentAtNode(sNew);
                sNew.remove();
            }}/>,
            sNew
        );
    }

}
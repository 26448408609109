var nb = new Map([
    ['production', 'Produksjoner'],
    ['calendar', 'Kalender'],
    ['search.title', 'Utforsk produksjoner'],
    ['headline.title', 'Utforsk produksjoner i DKS'],
    ['share_search', 'Del dette søket'],
    ['arranged_by', 'Arrangør'],
    ['location', 'Sted'],
    ['search.production.title', 'Søk etter skole, produksjon eller utøver']
]);
var nn = new Map([
    ['production', 'Produksjonar'],
    ['calendar', 'Kalendar'],
    ['search.title', 'Utforsk produksjonar'],
    ['headline.title', 'Utforsk produksjonar i DKS'],
    ['share_search', 'Del dette søket'],
    ['arranged_by', 'Arrangert av'],
    ['location', 'Stad'],
    ['search.production.title', 'Søk etter skule, produksjon eller utøvar']
]);
export function getTranslationText(language, key, def) {
    if (language === void 0) { language = 'nb'; }
    if (def === void 0) { def = ''; }
    switch (language) {
        case 'nb':
            return nb.get(key);
        case 'nn':
            return nn.get(key);
        default:
            return def;
    }
}
export function getTranslation(languages, language, key, subKey) {
    if (!languages) {
        return '';
    }
    return languages[language][key][subKey];
}

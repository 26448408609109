const KEYCODE = {
    ENTER: 13
};

(() => {
    const attachPostEvent = (classname, tagElement) => {
        jQuery(classname).each(function (index, element) {
            const postUrl = jQuery(element)?.find(tagElement)?.attr('href');
            postUrl && jQuery(element).on('click', function(event) { 
                window.location.href = postUrl;
            });
    
            jQuery(element).on('keyup', function(event) {
                event.which === KEYCODE.ENTER && event.target.click();
            });
        });
    };

    const listEvents = () => {
        attachPostEvent('.kt-headline-article-main', 'a');
        attachPostEvent('.kt-headline-article-small', 'h2 a');
        attachPostEvent('.kt-headline-article', 'h1 a');
        attachPostEvent('.kt-post', 'a');
    };

    window.addEventListener('load', listEvents);
})();
import React from "react";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: window.PRIMARY_COLOR,
    },
    secondary: {
      main: window.SECONDARY_COLOR,
    },
  },
  spacing: 4,
  typography: {
    fontSize: 16,
    useNextVariants: true,
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
      },
    },

    MuiOutlinedInput: {
      input: {
        padding: "5.5px 14px",
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: "translate(14px, 8px) scale(1)",
        fontSize: "1em",
      },
    },
    MuiInput: {
      root: {
        fontFamily: "Arial, serif",
      },
    },
  },
});

export { theme };

const KulturtankenThemeProvider = ({ children }) => (
  <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
);

export default KulturtankenThemeProvider;

import './polyfill';
import './custom';
import React, {lazy, Suspense} from 'react';
import ReactDOM from 'react-dom';
import Swiper from 'swiper/dist/js/swiper.js';
import 'swiper/dist/css/swiper.min.css';
import KulturtankenThemeProvider from "../utils/KulturtankenThemeProvider";
import ExploreProductionsWidget from "../widgets/ExploreProductionsWidget";
import '../../css/bundle.scss';
import LoadingCentered from "../components/LoadingCentered";
import {BugsnagErrorBoundary} from "../utils/BugsnagErrorBoundrary";
import {openSearchOption} from "../widgets/Productions/HeadlineSearch";
import AnchorDiv from "../utils/AnchorDiv";


window.Swiper = Swiper;

window.attachProductionsPage = (props) => {
    const ProductionsPage = lazy(() => import("../widgets/Productions/ProductionsPage"));

    ReactDOM.render(
        <BugsnagErrorBoundary>
            <KulturtankenThemeProvider>
                <Suspense fallback={<LoadingCentered/>}>
                    <ProductionsPage {...props}/>
                </Suspense>
            </KulturtankenThemeProvider>
        </BugsnagErrorBoundary>,
        document.getElementById('kt-productions')
    )
};

window.attachExploreProductionsWidget = (el, options) => {
    ReactDOM.render(
        <BugsnagErrorBoundary>
            <KulturtankenThemeProvider>
                <ExploreProductionsWidget {...options}/>
            </KulturtankenThemeProvider>
        </BugsnagErrorBoundary>,
        el
    )
};
window.attachProductionBreadcrumbs = (el) => {
    const ProductionBreadcrumbs = lazy(() => import("../widgets/ProductionBreadcrumbs"));

    ReactDOM.render(
        <BugsnagErrorBoundary>
            <KulturtankenThemeProvider>
                <Suspense fallback={null}>
                    <ProductionBreadcrumbs/>
                </Suspense>
            </KulturtankenThemeProvider>
        </BugsnagErrorBoundary>,
        el
    )
};
window.attachBranchOfficeDropDown = (el) => {
    const BranchOfficesDropDown = lazy(() => import("../widgets/BranchOfficesDropDown"));

    ReactDOM.render(
        <BugsnagErrorBoundary>
            <KulturtankenThemeProvider>
                <Suspense fallback={null}>
                    <BranchOfficesDropDown/>
                </Suspense>
            </KulturtankenThemeProvider>
        </BugsnagErrorBoundary>,
        el
    )
};

window.attachHeadlineSearch = (el, {url}) => {
    const HeadlineSearch = lazy(() => import("../widgets/Productions/HeadlineSearch"));

    ReactDOM.render(
        <BugsnagErrorBoundary>
            <KulturtankenThemeProvider>
                <Suspense fallback={null}>
                    <HeadlineSearch
                        variant={"filled"}
                        onSelect={openSearchOption(url)}
                    />
                </Suspense>
            </KulturtankenThemeProvider>
        </BugsnagErrorBoundary>,
        el
    )
};

window.addEventListener('load', () => {
    import('../utils/OnLoadActions');
});

window.openLightbox = (files, initialSlide, imageZoomable, autoPlayVideo) => {
    const Lightbox = lazy(() => import("../widgets/Lightbox"));

    ReactDOM.render(
        <BugsnagErrorBoundary>
            <KulturtankenThemeProvider>
                <Suspense fallback={null}>
                    <Lightbox
                        files={files}
                        initialSlide={initialSlide}
                        imageZoomable={!!imageZoomable}
                        autoPlayVideo={autoPlayVideo}
                    />
                </Suspense>
            </KulturtankenThemeProvider>
        </BugsnagErrorBoundary>,
        AnchorDiv.attachDivToBody()
    )
};

window.attachAttachmentTable = (attachments, links, el) => {
    const AttachmentsSection = lazy(() => import("../widgets/AttachmentsSection"));

    ReactDOM.render(
        <BugsnagErrorBoundary>
            <KulturtankenThemeProvider>
                <Suspense fallback={null}>
                    <AttachmentsSection
                        enableFotoware={false}
                        disabled={true}
                        value={{links, attachments}}
                    />
                </Suspense>
            </KulturtankenThemeProvider>
        </BugsnagErrorBoundary>,
        el
    )
};
import React from "react";
import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'

const bugsnagClient = bugsnag({apiKey: 'c00363681b22a84ece3a656374cf57a6', appVersion: process.env.APP_VERSION});
bugsnagClient.use(bugsnagReact, React);

const ErrorBoundary = bugsnagClient.getPlugin('react');

const BugsnagErrorBoundary = props => <ErrorBoundary {...props}/>;

export {BugsnagErrorBoundary, bugsnagClient};